import React from 'react'
import { graphql } from 'gatsby'
// import { RichText } from 'prismic-reactjs'
import Layout from '../../components/layouts'
import Banner from '../../components/common/Banner' 
// import { ImageCaption, Quote, Text } from '../../components/slices'

// Query for the Blog Post content in Prismic
export const query = graphql`
query PartnerQuery($uid: String) {
    prismic {
    allPartners(uid: $uid) {
        edges {
        node {
          logo
          name
          listeddate
          completed_projects
          partner_level
          qualified_count
          contact {
            _linkType
            ... on PRISMIC__ExternalLink {
              url
            }
          }
          website {
            ... on PRISMIC__ExternalLink {
              url
            }
          }
          about
          statements {
            statement
            writer
            statement_date
          }
          contact_info {
            city
            phone_number
            province
          }
        }
      }
    }
  }
}
`


// Display the title, date, and content of the Post
const PartnerBody = ({ partner }) => {

    // ABOUT INFO
    let aboutInfos = [];
    if (partner.about != null) {
        for (let i = 0; i < partner.about.length; i++) {
            const paragraph = partner.about[i];
            aboutInfos.push(
                <React.Fragment key={paragraph.text}>
                    {paragraph.text} <br />
                </React.Fragment>
            )   
        }
    }

    // CONTACT INFO
    let contactInfos = [];
    if (partner.contact_info != null) {
        partner.contact_info.forEach(element => {
            let formattedNumber;
    
            var cleaned = ('' + element.phone_number).replace(/\D/g, '')
            var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)
            if (match) {
                var intlCode = (match[1] ? '+1 ' : '')
                formattedNumber = [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('')
            }
            formattedNumber = element.phone_number;
    
            contactInfos.push(
                <span key={element.city}>
                    {element.city}, {element.province} {formattedNumber} <br />
                </span>
            )
        });
    }

    // STATEMENT INFO
    let statementInfos = [];
    if (partner.statements != null) {
        for (let i = 0; i < partner.statements.length; i++) {
            const statement = partner.statements[i];
            statementInfos.push(
                <blockquote key={statement.statement}>
                    <h6>{statement.statement}</h6>
                    <p className="author_name">{statement.writer}</p>
                </blockquote>
            )
        }
    }
  return (
    <section className="partner_details_area sec_pad">
        <div className="container">
            <div className="row flex-row-reverse">
                <div className="col-lg-5 pl_70">
                    <div className="partner_info">
                        <div className="info_head">
                            <i className="ti-crown"></i>
                            <h6 className="f_p f_600 f_size_22">Partner Details</h6>
                        </div>
                        <div className="info_item">
                            <i className=" ti-calendar"></i>
                            <h6>Listed On</h6>
                            <p>{partner.listeddate}</p>
                        </div>
                        <div className="info_item">
                            <i className="ti-pencil-alt"></i>
                            <h6>Project History</h6>
                            <p>{partner.completed_projects} Completed Projects</p>
                        </div>
                        <div className="info_item">
                            <i className="ti-cup"></i>
                            <h6>Partner Level</h6>
                            <p>{partner.partner_level}</p>
                        </div>
                        <div className="info_item">
                            <i className="ti-location-pin"></i>
                            <h6>Contact Info</h6>
                            <p>
                                {contactInfos}
                            </p>
                        </div>
                        <div className="info_item">
                            <i className=" ti-user"></i>
                            <h6>Qualified Professionals:</h6>
                            <p>{partner.qualified_count}</p>
                        </div>
                        <div className="info_item left">
                            {(partner.website != null) ? <><a href={partner.website.url} className="btn_get btn_hover">Visit Website</a><br /></> : <div></div>}
                            {(partner.contact != null) ? <><a href={partner.contact.url} className="btn_get btn_hover">Request a Quote</a></> : <div></div>}
                        </div>

                    </div>
                </div>
                <div className="col-lg-7">
                    <div className="details_content">
                        <div className="sec_title text-center mb_70">
                            <h2 className="f_p f_size_30 l_height50 f_600 mb-20">Partner Profile</h2>
                            {partner.logo !== null ? <img  src={partner.logo.url} alt="Company Logo" /> : <div></div>}
                        </div>
                        <div className="sec_title mb_70">

                            <p className="f_400 f_size_15">
                                {aboutInfos}
                            </p>
                        </div>
                        <div className="partner_details_content">
                            {statementInfos}
                        </div>



                    </div>
                </div>
            </div>
        </div>
    </section>
  );
}

export default (props) => {
    // Define the Partner content returned from Prismic
    const doc = props.data.prismic.allPartners.edges.slice(0,1).pop();
    if(!doc) return null;

    const partnerName = doc.node.name[0].text;
    // BANNER PROPS
    const title = () => (
        <div>
            <span>Let Our </span>Skilled<br /> Partners <span>Guide You</span>
        </div>
    )
    const bannerProps = {
        title: title,
        subtitle: "Partners > " + partnerName,
        // buttonLeft: {
        // text: "Become A Partner",
        // link: "/about/team"
        // },
        // buttonRight: {
        // text: "Find a Partner",
        // link: "/resources/partners"
        // }
    }

  return(
    <Layout>
        <Banner {...bannerProps}/>
        <PartnerBody partner={ doc.node } />
    </Layout>
  )
}
